<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2" v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">
            <div>
             <!-- <button @click="showAvertancy"> Checar advertencia</button>-->


                <!--   Hacer el cambio de idioma        -->
                <div class="row d-none">
                    <div class="col-md-8">
                        <div class="btn-taps text-center mt-2 mb-1">

                            <button v-for="i_lang in info_page.langs" class="btn btn-wd btn-default ml-1 mr-1" :class="info_page.tab_lang===i_lang.locale?'btn-info':'btn-outline'" @click="info_page.tab_lang=i_lang.locale">{{i_lang.name}}</button>

                        </div>
                    </div>
                </div>
                <!--   ***************************      -->


                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <fg-input type="text"
                                              :name="$t('form.name')"
                                              :label="$t('form.name')"
                                              :placeholder="$t('form.name')"
                                              v-validate="modelValidations.name"
                                              :error="getError($t('form.name'))"
                                              v-model="room.es.name">
                                    </fg-input>

                                </div>
                                <div class="col-md-6">
                                    <fg-input type="text"
                                              @input="room.cod = room.cod.toUpperCase()"
                                              :name="$t('room.form.code')"
                                              :label="$t('room.form.code')"
                                              :placeholder="$t('room.form.code')"
                                              v-validate="modelValidations.cod"
                                              :error="getError($t('room.form.code'))"
                                              v-model="room.cod"
                                              :disabled="(info_page.action==='edit')"
                                    >
                                    </fg-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <fg-input
                                            :label="$t('form.description')"  v-validate="modelValidations.description"  :name="$t('form.description')"  v-model="room.es.description"
                                            :error="getError($t('form.description'))">

                            <textarea class="form-control" :placeholder="$t('form.description')"
                                      v-model="room.es.description" rows="8"></textarea>
                                    </fg-input>

                                </div>
                            </div>
                        </div>



                    </div>

                    <div class="row">
                        <div class="col-md-12">

                            <label>Imagenes de la habitación - ({{$t('room.form.image_suggest')}})</label>
                            <label class="legend-sizes"></label>
                            <el-upload
                                    action="#"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove"
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="onBeforeUpload"
                                    :http-request="addAttachment"
                                    :limit="4"
                                    :on-exceed="handleExceed"
                                    :file-list="listImgs"
                                    :auto-upload="true"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">

                                <fg-input type="text"
                                          :name="$t('room.form.type_room')"
                                          :label="$t('room.form.type_room')"
                                          :placeholder="$t('room.form.type_room')"
                                          v-validate="modelValidations.type"
                                          :error="getError($t('room.form.type_room'))"
                                          v-model="room.type">
                                </fg-input>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label >{{$t('room.form.look')}}</label>

                                <fg-input
                                        v-validate="modelValidations.view"  :name="$t('room.form.look')"  v-model="room.view"
                                        :error="getError($t('room.form.look'))">

                                    <el-select class="select-default" size="large" :placeholder="$t('room.form.look')"  v-validate="modelValidations.view" :name="$t('room.form.look')"
                                               v-model="room.view"  :error="getError($t('room.form.look'))">
                                        <el-option v-for="option in room_info.views" class="select-default" :value="option"
                                                   :label="option" :key="option">
                                        </el-option>
                                    </el-select>
                                </fg-input>



                            </div>
                        </div>
                        <div class="col-md-4">
                            <fg-input type="text"
                                      :name="$t('room.form.surface')"
                                      :label="$t('room.form.surface')"
                                      :placeholder="'mt²'"
                                      v-validate="modelValidations.area"
                                      :error="getError($t('room.form.surface'))"
                                      v-model="room.area">
                            </fg-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label >{{$t('room.form.room_number')}}</label>


                                <fg-input
                                        v-validate="modelValidations.no_rooms"  :name="$t('room.form.room_number')"  v-model="room.no_rooms"
                                        :error="getError($t('room.form.room_number'))">

                                    <el-select class="select-default"  :placeholder="$t('room.form.room_number')"  v-validate="modelValidations.no_rooms" :name="$t('room.form.room_number')"
                                               v-model="room.no_rooms"  :error="getError($t('room.form.room_number'))">
                                        <el-option v-for="option in room_info.rooms_baths" class="select-default" :value="option"
                                                   :label="option" :key="option">
                                        </el-option>
                                    </el-select>
                                </fg-input>




                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group has-label">
                                <label >{{$t('room.form.bathroom_number')}}</label>

                                <fg-input
                                        v-validate="modelValidations.rooms_baths"  :name="$t('room.form.bathroom_number')"  v-model="room.rooms_baths"
                                        :error="getError($t('room.form.bathroom_number'))">

                                    <el-select class="select-default"  :placeholder="$t('room.form.bathroom_number')"  v-validate="modelValidations.no_baths" :name="$t('room.form.bathroom_number')"
                                               v-model="room.no_baths"  :error="getError($t('room.form.bathroom_number'))">
                                        <el-option v-for="option in room_info.rooms_baths" class="select-default" :value="option"
                                                   :label="option" :key="option">
                                        </el-option>
                                    </el-select>
                                </fg-input>




                            </div>
                        </div>
                        <div class="col-md-4">
                            <fg-input type="text"
                                      :name="$t('room.form.capacity_max')"
                                      :label="$t('room.form.capacity_max')"
                                      :placeholder="$t('room.form.capacity_max')"
                                      v-validate="modelValidations.max_pax"
                                      :error="getError($t('room.form.capacity_max'))"
                                      v-model="room.max_pax">
                            </fg-input>
                            <label  class="text-danger" v-show="c_errors.capacity.status"> Error</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 custom-error">
                            <fg-input type="text"
                                      :name="$t('room.form.adults_max')"
                                      :label="$t('room.form.adults_max')"
                                      :placeholder="$t('room.form.adults_max')"
                                      v-validate="modelValidations.max_adults"
                                      :error="getError($t('room.form.adults_max'))"
                                      v-model="room.max_adults"
                                      @input="c_errors.adults_max.status = false"
                            >
                            </fg-input>
                            <label  class="text-danger" v-show="c_errors.adults_max.status"> {{c_errors.adults_max.msg}}</label>
                        </div>
                        <div class="col-md-4 custom-error" >
                            <fg-input type="text"
                                      :name="$t('room.form.kids_max')"
                                      :label="$t('room.form.kids_max')"
                                      :placeholder="$t('room.form.kids_max')"
                                      v-validate="modelValidations.max_children"
                                      :error="getError($t('room.form.kids_max'))"
                                      v-model="room.max_children"
                                      @input="c_errors.kids_max.status = false"
                                      v-show="info_page.children"

                            >
                            </fg-input>
                            <label  class="text-danger" v-show="c_errors.kids_max.status"> {{c_errors.kids_max.msg}}</label>
                        </div>
                        <div class="col-md-4 custom-error">
                            <fg-input type="text"
                                      :name="$t('room.form.jrs_max')"
                                      :label="$t('room.form.jrs_max')"
                                      :placeholder="$t('room.form.jrs_max')"
                                      v-validate="modelValidations.jrs_max"
                                      :error="getError($t('room.form.jrs_max'))"
                                      v-model="room.max_jr"
                                      @input="c_errors.jrs_max.status = false"
                                      v-show="info_page.jrs"
                            >
                            </fg-input>
                            <label  class="text-danger" v-show="c_errors.jrs_max.status"> {{c_errors.jrs_max.msg}}</label>
                        </div>
                    </div>


                    <!--<div class="row">
                        <div class="col-md-8">
                            <div class="container-tags">
                                <h4 class="title">{{$t('room.form.contract')}}</h4>
                                <div class="box-inside">
                                    <div class="box-left">
                                        <input type="text" :placeholder="$t('form.filter')"
                                               class="form-control"
                                               v-model="fplugin.contracts.input_search"
                                               @keyup = "fplugin.contracts.array_list = find_item(fplugin.contracts.input_search, room_info.contracts)">
                                        <div class="box-items">
                                            <label v-for="contract in fplugin.contracts.array_list"
                                                   @click="addArray(contract, room.contracts)">{{contract.name}}</label>
                                        </div>
                                    </div>
                                    <div class="box-right">
                                        <el-tag
                                                :key="idx_key"
                                                v-for="(tag, idx_key) in room.contracts"
                                                size="small"
                                                type="default"
                                                :closable="true"
                                                class="custom"
                                                :close-transition="true"
                                                @close="handleClose(idx_key, room.contracts)">
                                            {{tag.name}}
                                        </el-tag>
                                    </div>
                                </div>
                                <div class="box-footer d-none">
                                    <div class="box-left">
                                        <button @click="fplugin.contracts.input_status=true"><i class="fa fa-plus-circle" ></i> Agregar nuevo valor agregado</button>
                                    </div>
                                    <div class="box-right">
                                        <input type="text"
                                               placeholder="New Tag"
                                               class="form-control"
                                               v-show="fplugin.contracts.input_status"
                                               v-model="fplugin.contracts.input_add"
                                               @keyup.enter="fplugin.contracts.input_add = createinArray(fplugin.contracts.input_add, room.contracts)"
                                               @blur="fplugin.contracts.input_add = createinArray(fplugin.contracts.input_add, room.contracts)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-md-8">
                            <div class="container-tags">
                                <h4 class="title">{{$t('room.form.amenities')}}</h4>
                                <div class="box-inside">
                                    <div class="box-left">
                                        <input type="text" :placeholder="$t('form.filter')"
                                               class="form-control"
                                               v-model="fplugin.amenities.input_search"
                                               @keyup = "fplugin.amenities.array_list = find_item(fplugin.amenities.input_search, room_info.amenities)">
                                        <div class="box-items">
                                            <label v-for="contract in fplugin.amenities.array_list"
                                                   @click="addArray(contract, room.amenities)">{{contract.name}}</label>
                                        </div>
                                    </div>
                                    <div class="box-right">
                                        <el-tag
                                                :key="idx_key"
                                                v-for="(tag, idx_key) in room.amenities"
                                                size="small"
                                                type="default"
                                                :closable="true"
                                                class="custom"
                                                :close-transition="true"
                                                @close="handleClose(idx_key, room.amenities)">
                                            {{tag}}
                                        </el-tag>
                                    </div>
                                </div>
                                <div class="box-footer2">
                                    <div class="box-left">
                                        <!--<button @click="fplugin.amenities.input_status=true" type="button"><i class="fa fa-plus-circle" ></i> {{$t('room.button.value_add')}}</button>-->
                                        <button @click="alertCapture('amenitie')" type="button"><i class="fa fa-plus-circle" ></i> {{$t('room.button.value_add')}}</button>
                                    </div>
                                    <div class="box-right">
                                        <input type="text"
                                               :placeholder="$t('room.form.values_new')"
                                               class="form-control"
                                               v-show="fplugin.amenities.input_status"
                                               v-model="fplugin.amenities.input_add"
                                               @keyup.enter="fplugin.amenities.input_add = createinArray(fplugin.amenities.input_add, room.amenities)"
                                               @blur="fplugin.amenities.input_add = createinArray(fplugin.amenities.input_add, room.amenities)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="container-tags">
                                <h4 class="title">{{$t('room.form.values_add')}}</h4>
                                <div class="box-inside">
                                    <div class="box-left">
                                        <input type="text" :placeholder="$t('form.filter')"
                                               class="form-control"
                                               v-model="fplugin.aggregates.input_search"
                                               @keyup = "fplugin.aggregates.array_list = find_item(fplugin.aggregates.input_search, room_info.aggregates)">
                                        <div class="box-items">
                                            <label v-for="contract in fplugin.aggregates.array_list"
                                                   @click="addArray(contract, room.aggregates)">{{contract.name}}</label>
                                        </div>
                                    </div>
                                    <div class="box-right">
                                        <el-tag
                                                :key="idx_key"
                                                v-for="(tag, idx_key) in room.aggregates"
                                                size="small"
                                                type="default"
                                                :closable="true"
                                                class="custom"
                                                :close-transition="true"
                                                @close="handleClose(idx_key, room.aggregates)">
                                            {{tag}}
                                        </el-tag>
                                    </div>
                                </div>
                                <div class="box-footer2">
                                    <div class="box-left">
                                        <!--<button @click="fplugin.aggregates.input_status=true"  type="button"><i class="fa fa-plus-circle" ></i> {{$t('room.button.value_add')}}</button>-->
                                        <button @click="alertCapture('aggregate')" type="button"><i class="fa fa-plus-circle" ></i> {{$t('room.button.value_add')}}</button>
                                    </div>
                                    <div class="box-right">
                                        <input type="text"
                                               :placeholder="$t('room.form.values_new')"
                                               class="form-control"
                                               v-show="fplugin.aggregates.input_status"
                                               v-model="fplugin.aggregates.input_add"
                                               @keyup.enter="fplugin.aggregates.input_add = createinArray(fplugin.aggregates.input_add, room.aggregates)"
                                               @blur="fplugin.aggregates.input_add = createinArray(fplugin.aggregates.input_add, room.aggregates)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-bed" v-for="(bed_item, idx_arr) in room.beds">

                        <label class="h-title">Arreglo {{idx_arr+1}}:</label>


                        <div class="item-beds" v-for="(bed, idx_bed) in bed_item.items">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group has-label">
                                        <label v-if="idx_bed===0">{{$t('room.form.bed_type')}}</label>
                                        <el-select class="select-default" size="large" :placeholder="$t('room.form.bed_type')"  v-validate="modelValidations.category" :name="$t('room.form.bed_type')"
                                                   v-model="bed._id"  :error="getError($t('room.form.bed_type'))" @change="checkDifference(idx_arr)">
                                            <el-option v-for="option in bed_item.types" class="select-default" :value="option._id"
                                                       :label="option.name" :key="option._id" v-show="option.status">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-md-4">

                                    <div class="form-group has-label">
                                        <label  v-if="idx_bed===0">{{$t('room.form.bed_number')}}</label>
                                        <el-select class="select-default" size="large" :placeholder="$t('room.form.bed_number')"  v-validate="modelValidations.category" :name="$t('room.form.bed_number')"
                                                   v-model="bed.number"  :error="getError($t('room.form.bed_number'))">
                                            <el-option v-for="option in 5" class="select-default" :value="option"
                                                       :label="option" :key="option">
                                            </el-option>
                                        </el-select>
                                        <div class="custom-error">
                                            <label class="text-danger" v-show="bed.number===''&& bed._id!=='' && room.bedsError">Es requirerdo agregar el número de camas</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <a  v-if="idx_bed!==0" class="btn-action" @click="deleteBed(idx_arr, idx_bed)"><i class="fa fa-trash" ></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="box-button">
                                    <button type="button" @click="addBed(idx_arr)" class="add-item" v-if="bed_item.items.length<(bed_item.types.length-1)"><i class="fa fa-plus-circle" ></i> {{$t('room.button.bed_add')}}</button>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="box-button">
                                    <button type="button" @click="rmArrayBed(idx_arr)" class="add-item btn-danger" v-show="idx_arr>0"><i class="fa fa-trash" ></i> Quitar arreglo de camas</button>
                                </div>
                            </div>
                            <div class="col-md-4">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="lbl-main">{{$t('room.form.bed_question')}}</label>
                        </div>

                        <div class="col-md-6"></div>
                    </div>

                    <div class="row">

                        <div class="col-md-4">
                            <div class="box-button">
                                <button type="button" @click="addArrayBed" class="add-item"><i class="fa fa-plus-circle" ></i> {{$t('room.button.array_add')}}</button>
                            </div>
                        </div>
                        <div class="col-md-8">

                        </div>

                    </div>

                    <div class="text-center mt-3 mb-3">
                        <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                            {{info_page.button}}
                        </button>
                        <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Rooms'})">
                            {{$t('form.cancel')}}
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag, Upload, Dialog} from 'element-ui'

    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import ContractService from '../../../js/services/ContractService';
    import {VTab, VueTabs} from 'vue-nav-tabs'
    import Fuse from 'fuse.js'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    const propertyService = new PropertyService();
    const roomService = new RoomService();
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'
    import moment from 'moment';

    import { mapGetters } from "vuex";
    const contractService = new ContractService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            Checkbox,
            VueTabs,
            VTab,
            Breadcrumb,
            BreadcrumbItem,
            [Upload.name]: Upload,
            [Dialog.name]: Dialog,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                room_original: null,
                screenLoading: false,
                screenText: '',
                dialogImageUrl: '',
                fullscreenLoading: false,
                dialogVisible: false,
                listImgs:[],
                imgsBash:[],
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('room.index.title'),
                        path: '/admin/rooms',
                        type: '',
                    },

                    {
                        name: this.$t('room.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],
                vars:{
                    rateConfigs:[],
                    rateplans:[]
                },
                info_page:{
                    original_info:{},
                    section_name: 'room',
                    action: 'add',
                    header: this.$t('room.new.title'),
                    button:  this.$t('form.save'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    show_array: false,
                    cont_img: 0,
                    number_img: 0,
                    property: null,
                    jrs: false,
                    children: false,
                    info_links:{
                        title:{
                            normal: ['Tarifa Base', 'Extra Pax'],
                            extendible: ['', 'Sencilla', 'Doble', 'Triple', 'Cuadruple', 'Quintuple', 'Sextuple', 'Septima', 'Octava', 'Novena', 'Decima']
                        }
                    },
                },

                fuseSearch: null,
                room: {
                    _id: '',
                    cod: '',
                    property: '',
                    type: '',
                    area: '',
                    view: '',
                    no_rooms: '',
                    no_baths: '',
                    max_pax: '',
                    max_adults: '',
                    max_children: 0,
                    max_jr: 0,
                    other_array: '',
                    contracts: [],
                    aggregates: [],
                    amenities: [],
                    beds: [],
                    bedsError: false,
                    images: [

                    ],
                    es:{
                        name: '',
                        description: '',

                    },
                    en:{
                        name: '',
                        description: '',

                    },
                    all_images:[

                    ],
                    image:[
                        {
                            _id: '',
                            img: '',
                            status: true,
                            order: 1
                        },
                        {
                            _id: '',
                            img: '',
                            status: true,
                            order: 2
                        },
                        {
                            _id: '',
                            img: '',
                            status: true,
                            order: 3
                        },
                        {
                            _id: '',
                            img: '',
                            status: true,
                            order: 4
                        },
                        /*top:null,
                        top_front: null,
                        item_a: null,
                        item_a_front: null,
                        item_b: null,
                        item_b_front: null,
                        item_c: null,
                        item_c_front: null,*/

                    ]
                },
                room_info: {
                    amenities:[
                        {name: 'Aire acondicionado'},
                        {name: 'Amenidades de baño'},
                        {name: 'Bañera de hidromasaje'},
                        {name: 'Bata de baño'},
                        {name: 'Cable/satélite'},
                        {name: 'Cafetera y kit de café'},
                        {name: 'Caja fuerte digital'},
                        {name: 'Calefacción'},
                        {name: 'Cocina'},
                        {name: 'Espejo de vanidad'},
                        {name: 'Kit de planchado'},
                        {name: 'Minibar'},
                        {name: 'Minibar gratis'},
                        {name: 'Pantuflas'},
                        {name: 'Teléfono'},
                        {name: 'Secador de cabello'},
                        {name: 'Smart tv'},
                        {name: 'Ventilador de piso'},
                        {name: 'Ventilador de techo'},
                    ],
                    types: [
                        'Resort',
                        'Estándar',
                        'Bungalo',
                        'Condo',
                        'Chalet',
                    ],
                    views: [
                        'Vista al Mar',
                        'Vista Frente al Mar',
                        'Vista al Interior',
                        'Vista al Jardin',
                        'Vista a la Ciudad',
                        'Vista Parcial al Mar',
                        'Vista a la Alberca',
                        'Vista a las Montañas',
                        'Vista a la Marina o Montañas',
                        'Vista a la Marina',
                    ],
                    qtys: [],
                    bathrooms: [],
                    types_bed: [
                        {
                            _id:'',
                            name:'Seleccionar',
                            status:true
                        },
                        {
                            _id:'single',
                            name:'Individual',
                            status:true
                        },
                        {
                            _id:'double',
                            name:'Matrimonial',
                            status:true
                        },
                        {
                            _id:'king',
                            name:'King size',
                            status:true
                        },
                        {
                            _id:'queen',
                            name:'Queen size',
                            status:true
                        },
                        {
                            _id:'couch',
                            name:'Sofa cama',
                            status:true
                        },
                        {
                            _id:'bunk',
                            name:'Litera',
                            status:true
                        }
                    ],
                    numbers_bed: [],
                    contracts: [
                        {
                            _id: '1',
                            name: 'Contrato base'
                        },
                        {
                            _id: '2',
                            name: 'Contrato facebook'
                        },
                        {
                            _id: '3',
                            name: 'Contrato para móviles'
                        }
                    ],
                    aggregates: [
                        {
                            name: 'Upgrade gratuito'
                        },
                        {
                            name: 'Bebida de bienvenida'
                        },
                        {
                            name: 'Créditos del complejo'
                        },
                        {
                            name: 'Créditos para golf'
                        },
                        {
                            name: 'Créditos para spa'
                        },
                        {
                            name: 'Desayuno incluido'
                        },
                        {
                            name: 'Estacionamiento gratuito'
                        },
                        {
                            name: 'Llegada temprana'
                        },
                        {
                            name: 'Masaje de bienvenida'
                        },
                        {
                            name: 'Pet friendly'
                        },
                        {
                            name: 'Salida tarde'
                        },
                        {
                            name: 'Servicio de habitaciones gratuito'
                        },
                        {
                            name: 'Traslado al aeropuerto'
                        },
                        {
                            name: 'Wi-Fi gratis'
                        }
                    ],
                    rooms_baths: [1,2,3,4,5],
                    oldConfigs: []
                },
                fplugin:{
                    contracts:{
                        array_list: [],
                        input_add: '',
                        input_status: true,
                        input_search: ''
                    },

                    aggregates:{
                        array_list: [],
                        input_add: '',
                        input_status: false,
                        input_search: ''
                    },

                    amenities:{
                        array_list: [],
                        input_add: '',
                        input_status: false,
                        input_search: ''
                    },
                },
                modelValidations: {
                    name: {
                        required: true
                    },
                    description: {
                        required: true
                    },
                    cod: {
                        required: true
                    },
                    type: {
                        required: true
                    },
                    area: {
                        required: true
                    },
                    view: {
                        required: true
                    },
                    no_rooms: {
                        required: true
                    },
                    no_baths: {
                        required: true
                    },
                    max_pax: {
                        required: true,
                        min_value: 1,
                        numeric: true
                    },
                    max_adults: {
                        required: true,
                        min_value: 1,
                        numeric: true
                    },
                    max_children: {
                        required: true,
                        numeric: true
                    },
                    jrs_max: {
                        required: true,
                        numeric: true
                    },
                },
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                c_errors:{
                    capacity:{
                        status: false,
                        msg:''
                    },
                    adults_max:{
                        status: false,
                        msg: '',
                    },
                    kids_max:{
                        status: false,
                        msg: '',
                    },
                    jrs_max:{
                        status: false,
                        msg: '',
                    }
                },
                section: 'ROOMS_RATES'
            }
        },

        mounted() {
            //this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})

            this.changeProperty()

        },
        created(){
            this.initPage(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION', this.section);
        },
        methods: {


            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                   //console.log(property_id, 'property_id');

                    if(this.$route.name==='editRoom'){
                        this.$router.push({
                            name: 'Rooms'
                        });
                    }if(this.$route.name==='createRoom'){
                        this.initPage(property_id)
                    }
                })
            },
            endLoader(){
                if(!(this.$route.name==='editRoom')){
                    this.$refs.topProgress3.done()
                }
            },

            async initPage(property_id){
                this.initLoading();
                //this.$refs.topProgress3.start();
                this.fplugin.contracts.array_list =  this.room_info.contracts;
                this.fplugin.aggregates.array_list =  this.room_info.aggregates;
                this.fplugin.amenities.array_list =  this.room_info.amenities;
                this.configLogs(property_id, this.info_page.section_name);

                this.room.property = property_id;

                let room_id = this.$route.params.id;

                await this.getInfoProperty(property_id);

               //console.log('propertyResponse', ));


                if(this.$route.name==='editRoom'){

                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('room.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('room.edit.header');

                    this.updateData(room_id);
                }else{
                    let types_array = JSON.parse(JSON.stringify(this.room_info.types_bed));
                    this.room.beds.push({
                        _id: '',
                        group: 1,
                        types:types_array,
                        items:[
                            {
                                number: '',
                                _id:''
                            }
                        ]
                    });
                    setTimeout(() => {
                        this.closeLoading();
                    }, 500);
                }
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            async getInfoProperty(property_id){
                let propertyResponse = this.selected_property;
                //console.log('propertyResponse', propertyResponse);

                //propertyResponse = propertyResponse.data.data.property;
                this.info_page.property =propertyResponse;
                this.info_page.children = (!propertyResponse.only_adults && propertyResponse.age_child)?true:false;

                this.info_page.jrs = (!propertyResponse.only_adults && propertyResponse.age_jr && propertyResponse.plan_ai)?true:false;
                //return propertyResponse.data.data.property;age_jr
            },
            async updateData(room_id) {

                let rateplans = await contractService.getContracts(true, this.selected_property._id);
                rateplans = rateplans.data.data.rateplans;

                roomService.getRoomId(room_id).then(response => {
                    let room  = response.data.data.room;
                    this.room_original = room;
                    this.info_page.original_info =  JSON.parse(JSON.stringify(room))
                   
                    this.room._id = room._id;
                    this.room.cod = room.cod;
                    this.room.property = room.property;
                    this.room.type = room.type;
                    this.room.area = room.area;
                    this.room.view = room.view;
                    this.room.no_rooms = room.no_rooms;
                    this.room.no_baths = room.no_baths;
                    this.room.max_pax = room.max_pax;
                    this.room.max_adults = room.max_adults;
                    this.room.max_children = room.max_children;
                    this.room.max_jr = room.max_jr;
                    this.room.es.name = room.name;
                    this.room.es.description = room.description;
                    this.room.aggregates = room.agregate_values;
                    this.room.amenities = room.amenities;

                    let roomConfigs = room.room_configs;
                    let rateConfigs = room.rateconfig
                    let sliders = room.slider;
                   //console.log('room', sliders);

                    rateConfigs.forEach((rateConfig, idx)=>{
                        let info_rateplan = rateplans.find(item=> item._id === rateConfig.rateplan)
                        if(info_rateplan && (info_rateplan.rateplan_link===undefined || info_rateplan.rateplan_link===null)){
                            rateConfig.rateplan_name = info_rateplan.name;
                            rateConfig.meal_plan = info_rateplan.meal_plan;
                            this.vars.rateConfigs.push(rateConfig);
                        }
                    });



                   //
                    /*listTest:[{
                        name: 'food.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                    }, {
                        name: 'food2.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
                    }],*/

                    if(sliders && sliders.length){
                        sliders.forEach((slider, idx)=>{
                            /*this.room.image[idx]._id = slider._id;
                            this.room.image[idx].img = slider.img;
                            this.room.image[idx].order = slider.order;
                            this.room.all_images.push(slider._id);*/
                            this.listImgs.push({
                                _id: slider._id,
                                name: slider._id,
                                url: slider.img,
                                onServer: true,
                            });

                        })
                    }


                    let types_array =this.room_info.types_bed;

                    roomConfigs.forEach((item, idx)=>{
                        //console.log(item, 'item')
                        let model_rommConfig = {
                            _id: item._id,
                            group: (idx+1),
                            types: JSON.parse(JSON.stringify(types_array)),
                            items:[]
                        };

                        this.room_info.oldConfigs.push(item._id);

                        if(item.single>0){
                            model_rommConfig.items.push({
                                number: item.single,
                                _id:'single'
                            })
                            model_rommConfig.types[0].status = false;
                        }
                        if(item.double>0){
                            model_rommConfig.items.push({
                                number: item.double,
                                _id:'double'
                            })
                            model_rommConfig.types[1].status = false;
                        }
                        if(item.king>0){
                            model_rommConfig.items.push({
                                number: item.king,
                                _id:'king'
                            })
                            model_rommConfig.types[2].status = false;
                        }
                        if(item.queen>0){
                            model_rommConfig.items.push({
                                number: item.queen,
                                _id:'queen'
                            })
                            model_rommConfig.types[3].status = false;
                        }
                        if(item.couch>0){
                            model_rommConfig.items.push({
                                number: item.couch,
                                _id:'couch'
                            })
                            model_rommConfig.types[4].status = false;
                        }
                        if(item.bunk>0){
                            model_rommConfig.items.push({
                                number: item.bunk,
                                _id:'bunk'
                            })
                            model_rommConfig.types[5].status = false;
                        }
                        this.room.beds.push(model_rommConfig);
                    });

                    if(roomConfigs.length===0){
                        let types_array = JSON.parse(JSON.stringify(this.room_info.types_bed));
                        this.room.beds.push({
                            _id: '',
                            group: 1,
                            types:types_array,
                            items:[
                                {
                                    number: '',
                                    _id:''
                                }
                            ]
                        });
                    }
                    setTimeout(() => {
                        this.closeLoading();
                    }, 500);
                });
            },
            sendForm(action){
                this.validate(action);
            },
            saveItem(){

                this.initLoading('Guardando')
                let beds_arrays = this.room.beds;
                let bedConfig = [];
                //console.log('save')

                let save_configs = false;
                beds_arrays.forEach(bed_info=>{
                    let base_roomCondfig = {
                        single: 0,
                        double: 0,
                        king: 0,
                        queen: 0,
                        couch: 0,
                        bunk: 0,
                        availability: 0
                    };
                    let bed_items = bed_info.items;
                    bed_items.forEach(bed=>{
                        //console.log('before send', isNaN(bed.number), isNaN('test') )
                        switch (bed._id) {
                            case 'single':
                                if(bed.number!==''){
                                    base_roomCondfig.single = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'double':
                                if(bed.number!==''){
                                    base_roomCondfig.double = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'king':
                                if(bed.number!==''){
                                    base_roomCondfig.king = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'queen':
                                if(bed.number!==''){
                                    base_roomCondfig.queen = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'couch':
                                if(bed.number!==''){
                                    base_roomCondfig.couch = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'bunk':
                                if(bed.number!==''){
                                    base_roomCondfig.bunk = bed.number;
                                    save_configs = true;
                                }
                                break;
                        }
                    });
                    bedConfig.push(base_roomCondfig)

                });
                roomService.createRoom(this.room)
                    .then(async (response) => {
                        let room = response.data.data.room;

                        await this.createLog(room._id, 0)

                        if(save_configs){
                            await bedConfig.forEach(async item=>{
                                await roomService.createRoomConfig(item, room._id)
                            })
                        }
                        //let cont_img = 1;
                        let newItems = this.listImgs.filter(item=> item.onServer === false)

                        //console.log('allImages', this.listImgs, oldImages, newItems)
                        if(newItems.length){
                            let text = (newItems.length===1)?'Guardando imagen':'Guardando imagenes'
                            swal({
                                title: text,
                                html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
                                ,
                                showConfirmButton: false,
                                buttonsStyling: false
                            })
                            this.updateImages(room._id);
                        }else{

                            this.closeLoading();
                        }
                        this.showSwal(this.$t('room.new.msg'));


                    })
                    .catch(error => {
                        if(error.response.data.err === "Room validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                    })
                    .finally(() => {
                    });
            },

            saveImages(room_id){
               //console.log('start: images');
                if(this.room.image.length>0){

                   //console.log('array init:', this.room.image);
                    let img_proccess = this.room.image[0];

                   //console.log('img selected:', img_proccess);
                    this.saveImage(room_id, img_proccess.img, 'POST', img_proccess._id )


                }else{

                   //console.log('end proccess');
                    return true;
                }

            },

            saveImage(room_id, image_item, action, img_id){


                if(image_item!=='' && img_id===''){
                    let image = this.processImage(image_item, image_item.name)
                    let ext = image.type.replace('image/', '');
                    //console.log(image_item, 'img')

                    let formData = new FormData();
                    formData.append('img', image, image.name);
                    formData.append('property', this.selected_property.name);

                    roomService.uploadSlider(room_id, formData, action).then(response=>{
                        this.info_page.cont_img++;
                       //console.log('this.info_page.cont_img', this.info_page.cont_img);
                        this.room.image.splice(0, 1)
                        //console.log('array result:', this.room.image);
                        this.saveImages(room_id)
                        if(this.info_page.cont_img>this.info_page.number_img){
                            this.showSwal(this.$t('room.new.msg'));
                        }
                    })
                }else{
                    //console.log('remove: image array');
                    this.room.image.splice(0, 1)
                    //console.log('array result:', this.room.image);
                    this.saveImages(room_id)
                }

            },
            processImage(data, filename) {

                let arr = data.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);

                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1);
                    n -= 1;
                }

                return new File([u8arr], filename, { type: mime })
            },
            removeImage(position){

                this.room.image[position]._id =''
                this.room.image[position].img =''


            },

            async updateImages(room_id){
                let newItems = this.listImgs.filter(item=> item.onServer === false);
               //console.log('start proccess', newItems);
                let idxImg = 0;

                while(newItems.length>idxImg){
                    let img_proccess = newItems[idxImg];

                    let image = this.processImage(img_proccess.url, img_proccess.name)
                    let ext = image.type.replace('image/', '');
                    let formData = new FormData();
                    formData.append('img', image, image.name);
                    formData.append('property', this.selected_property.name);
                    let roomResponse = await roomService.uploadSlider(room_id, formData, 'POST');
                         /* .then(response=>{
                        this.info_page.cont_img++;
                    })*/

                    idxImg++;
                }
                this.closeLoading();
                await this.showSwal(this.$t('room.edit.msg'));
                return true;
                /*if(newItems.length){

                    let img_proccess = newItems[0];
                    await this.updateImg(room_id, img_proccess)
                }else{


                    this.closeLoading();
                    await this.showSwal(this.$t('room.edit.msg'));
                    return true;
                }*/
            },
            async updateImg(room_id, img_proccess){
                let image = this.processImage(img_proccess.url, img_proccess.name)
                let ext = image.type.replace('image/', '');
                let formData = new FormData();
                formData.append('img', image, image.name);
                formData.append('property', this.selected_property.name);

                let roomResponse = roomService.uploadSlider(room_id, formData, 'POST').then(response=>{
                        this.info_page.cont_img++;
                        let idxKey = this.listImgs.findIndex(item => item.uid===img_proccess.uid)
                        this.listImgs.splice(idxKey, 1)
                        //this.listImgs.splice(idxKey, 1)
                        //console.log('array result:', this.room.image);
                        this.updateImages(room_id)
                    })


                /*
                roomService.uploadSlider(room_id, formData, 'POST').then(response=>{
                    //console.log('this.info_page.cont_img', this.info_page.cont_img);
                })*/

            },

            async deleteBash(){
                if(this.imgsBash.length){
                    let image_id = this.imgsBash[0];
                    await roomService.deleteImage(this.room._id, image_id);
                    this.imgsBash.splice(0, 1)
                    this.deleteBash();
                }
                return true;
            },



            async updateItem(){
                this.initLoading('Guardando')

                let beds_arrays = this.room.beds;
                let bedConfig = [];

                let oldConfigs = this.room_info.oldConfigs;
                let save_configs = false;
                let oldImages = this.room.all_images;




                beds_arrays.forEach(bed_info=>{
                    let base_roomCondfig = {
                        _id: '',
                        single: 0,
                        double: 0,
                        king: 0,
                        queen: 0,
                        couch: 0,
                        bunk: 0,
                        availability: 0
                    };
                    base_roomCondfig._id = bed_info._id;
                    let bed_items = bed_info.items;
                    bed_items.forEach(bed=>{
                        switch (bed._id) {
                            case 'single':
                                if(bed.number!==''){
                                    base_roomCondfig.single = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'double':
                                if(bed.number!==''){
                                    base_roomCondfig.double = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'king':
                                if(bed.number!==''){
                                    base_roomCondfig.king = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'queen':
                                if(bed.number!==''){
                                    base_roomCondfig.queen = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'couch':
                                if(bed.number!==''){
                                    base_roomCondfig.couch = bed.number;
                                    save_configs = true;
                                }
                                break;
                            case 'bunk':
                                if(bed.number!==''){
                                    base_roomCondfig.bunk = bed.number;
                                    save_configs = true;
                                }
                                break;
                        }
                    });
                    bedConfig.push(base_roomCondfig)

                });

                if(save_configs){
                    let idxConfig = 0;
                    while(bedConfig.length>idxConfig){
                        let item = bedConfig[idxConfig];
                        if(item._id!==''){
                            let index = oldConfigs.indexOf(item._id);
                            (index>=0)?oldConfigs.splice(index, 1):'';
                            //
                            await roomService.updateRoomConfig(item, this.room._id)
                            /*responseThis = await roomService.updateRoomConfig(item, this.room._id)
                            console.log('responseThis._id', responseThis)*/

                        }else{
                            await roomService.createRoomConfig(item, this.room._id)
                        }
                        idxConfig++;
                    }

                }
                /*await oldConfigs.forEach(async (config_id, idx) => {
                    await roomService.deleteRoomConfig(config_id, this.room._id);
                })*/
                let idxConfig = 0;
                while(oldConfigs.length>idxConfig){
                    let config_id = oldConfigs[idxConfig]
                    await roomService.deleteRoomConfig(config_id, this.room._id);
                    idxConfig++;
                };


                let newItems = this.listImgs.filter(item=> item.onServer === false)

                //console.log('allImages', this.listImgs, oldImages, newItems)


                await this.deleteBash(this.room._id);
                if(newItems.length){
                    await this.updateImages(this.room._id);
                }

                roomService.updateRoom(this.room)
                    .then(async (response) => {

                        let roomResponse = response.data.data.room;
                        console.log('prueba', 'background: #222; color: #bada55');
                        console.log(this.info_page.original_info);
                        console.log(roomResponse);
                        let changes = logFunctions.cheackProp(this.info_page.original_info, roomResponse, this.info_page.section_name)
                        if(changes.length){
                            await this.createLog(this.room._id, 1, changes);
                        }

                        this.closeLoading();
                        await this.showSwal(this.$t('room.edit.msg'));




                        /*await this.room.image.forEach((item, idx)=>{
                            //console.log('item_img', item)
                            if(item.img!==''){
                                if(item._id!==''){
                                    //console.log('actualizando', item._id)
                                    let index = oldImages.indexOf(item._id);
                                    (index>=0)?oldImages.splice(index, 1):'';
                                }
                            }
                        })
                        await oldImages.forEach(async (image_id, idx) => {
                            await roomService.deleteImage(this.room._id, image_id);
                        })

                        this.room.image.forEach(item=>{
                            if(item.img!=='' && item._id  === ''){
                                this.info_page.number_img++;
                            }
                        })
                        if(this.info_page.number_img>0){
                            let text = (this.info_page.number_img===1)?'Guardando imagen':'Guardando imagenes'
                            swal({
                                title: text,
                                html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
                                ,
                                showConfirmButton: false,
                                buttonsStyling: false
                            })
                        }

                        await this.saveImages(this.room._id)


                        //this.saveImage(this.room._id, item.img, 'POST', item._id )


                        if(this.room.image.length===0){
                            await this.showSwal(this.$t('room.edit.msg'));
                        }*/

                        //
                    })
                    .catch(error => {
                        if(error.response.data.err === "Validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                        this.closeLoading();
                       //console.log(error);
                    })
                    .finally(() => {
                    });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('room.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {


                    if(this.$route.name==='editRoom'){
                        this.showAvertancy();
                        /*this.$router.push({
                            name: 'Rooms'
                        });*/
                    }else{
                        this.$router.push({
                            name: 'Rooms'
                        });
                    }
                });
            },
            handleShow(id) {


                let routeData = this.$router.resolve({name: 'editContract', params: {id: id}});
                window.open(routeData.href, '_blank');

            },
            showAvertancy(){
                //console.log('alertError', row, contracts)
                let htmlList= ''
                let option = '';
                let rateplans = this.vars.rateConfigs;

                let showAdvertency = false;
                htmlList+='<p>Es necesario actualizar los siguientes contratos:</p>'
                htmlList+= '<div class="text-left box-info-rateplans">'
                rateplans.forEach(rateplan=>{

                    let pushMsg = false;
                    let htmlAuxiliar = '';
                    if(rateplan.meal_plan === 3){
                        console.log('rateplan.jrs_price', rateplan.jrs_price);
                        let routeData = this.$router.resolve({name: 'editContract', params: {id: rateplan.rateplan}});
                        htmlAuxiliar +='<a href="'+routeData.href+'" target="_blank"> '+rateplan.rateplan_name+' <i class="el-icon-edit ml-2"></i></a>';
                        htmlAuxiliar+= '<ul style="text-align: left">'
                        if(this.room.max_jr>0 && (rateplan.jrs_price === undefined || rateplan.jrs_price === null)){
                            htmlAuxiliar +='<li>Tarifa para junior(s)</li>';
                            pushMsg = true;
                        }
                        if(this.room.max_children>0 && (rateplan.children_price === undefined || rateplan.children_price === null)){
                            htmlAuxiliar +='<li>Tarifa para niño(s)</li>';
                            pushMsg = true;
                        }

                        for(let i=3; i<=this.room.max_adults; i++){

                            let existAssign = rateplan.assignments.find(item => item.cant === i);
                            if(existAssign === undefined){
                                htmlAuxiliar +='<li>Tarifa para '+this.info_page.info_links.title.extendible[i]+'</li>';
                                pushMsg = true;
                            }
                        }

                        htmlAuxiliar+= '</ul>';
                        if(pushMsg){
                            htmlList+=htmlAuxiliar;
                            showAdvertency = true;
                        }
                    };

                })
                htmlList+= '</div>'
                /*htmlList+= '<ul class="ml-3" style="text-align: left">'
                rateplans.forEach(rateplan=>{
                    htmlList +='<li>'+rateplan.rateplan_name+'</li>';
                })
                htmlList+= '</ul>'*/



                if(showAdvertency){
                    swal({
                        title: 'Advertencia',
                        type: 'warning',
                        html:htmlList,
                        confirmButtonText: 'Aceptar',
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill'
                    }).then(()=> {
                        this.$router.push({
                            name: 'Contracts'
                        })
                    });
                }else{
                    this.$router.push({
                        name: 'Rooms'
                    })
                }

            },



            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {
                this.customValidity();

                // console.log('se manda a guardar2')
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                       //console.log('se manda a guardar', this.customValidity(), this.arrayValidity() )
                        if(this.customValidity() && this.arrayValidity()){
                           //console.log('se manda a guardar2')
                            if(action==='add'){
                                this.saveItem();
                            }else{
                                this.updateItem();
                            }
                        }
                    }
                })
            },
            customValidity(){
                let max_pax = parseInt(this.room.max_pax);
                let max_adults = parseInt(this.room.max_adults);
                let max_children = parseInt(this.room.max_children);
                let max_jr = parseInt(this.room.max_jr);
                let errors = true;
               //console.log('Error: max_jr', max_jr)

                if(max_adults>max_pax){
                    let error_am = this.c_errors.adults_max;
                    error_am.status = true;
                    error_am.msg = 'El Máximo de adultos no puede ser mayor a la capacidad';
                    errors = false;
                }
                if(max_children>=max_pax){
                    //console.log('Error: el maximo de adultos no puede ser mayor a la capacidad')
                    let error_am = this.c_errors.kids_max;
                    error_am.status = true;
                    error_am.msg = 'El Máximo  de niños no puede ser mayor a la capacidad';
                    errors = false;
                }
                if(max_jr>=max_pax){
                    //console.log('Error: el maximo de adultos no puede ser mayor a la capacidad')
                    let error_am = this.c_errors.jrs_max;
                    error_am.status = true;
                    error_am.msg = 'El Máximo de juniors no puede ser mayor a la capacidad';
                    errors = false;
                }
                return errors;
            },
            arrayValidity(){
                let beds_arrays = this.room.beds;
                let error = true;
                beds_arrays.forEach(bed_info=> {

                    let bed_items = bed_info.items;
                    bed_items.forEach(bed => {
                        //console.log('before send', isNaN(bed.number), isNaN('test') )
                        if (bed._id !== '' && bed.number === '') {
                            error = false;
                            this.room.bedsError = true;
                        }
                    });
                });
                return error;
            },

            alertError(){
                swal({
                    title: 'El código de la habitación debe ser único',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Modificar',
                    buttonsStyling: false
                }).then((result)=> {

                });

            },
            /******************* FUNCIONES PARA TEXTAREA MULTIPLE VERSION ADRIAN **************************************/
            alertCapture(type){
                let title_t = '',
                    placeholder_t = '',
                    text_succes = '',
                    text_error = '';
                let array_aux = [];
                if(type==='amenitie'){
                    title_t=  'Amenidades'
                    placeholder_t = 'Escribe la nueva amenidad';
                    text_succes= 'Amenidad creada exitosamente';
                    text_error = 'Esta amenidad ya existe';
                    array_aux = this.room.amenities;
                }else if(type==='aggregate'){
                    title_t=  'Valores agregados'
                    placeholder_t = 'Escribe el nuevo valor agregado';
                    text_succes= 'Valor agregado creado exitosamente';
                    text_error = 'Este valor agregado ya existe';
                    array_aux = this.room.aggregates;

                }
                //console.log('array', array_aux)


                swal({
                    title: title_t,
                    html: '<div class="form-group">' +
                        '<input id="myTestField" type="text" class="form-control"  placeholder="'+placeholder_t+'"/>' +
                        '</div>',
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    confirmButtonText: 'Agregar',
                    cancelButtonClass: 'btn btn-danger btn-fill',
                    cancelButtonText: 'Cancelar',
                    buttonsStyling: false
                }).then( (result)=> {
                    if (result.dismiss !== 'cancel') {
                        let inputt = document.getElementById('myTestField').value;

                        //console.log('tt', inputt, array_aux);
                        let response = this.createinArray(inputt, array_aux);

                        if(inputt.trim().length>0){
                            if(response){
                                swal({
                                    type: 'success',
                                    text: text_succes,
                                    confirmButtonClass: 'btn btn-success btn-fill',
                                    buttonsStyling: false

                                })
                            }else{
                                swal({
                                    type: 'warning',
                                    text: text_error,
                                    confirmButtonClass: 'btn btn-success btn-fill',
                                    buttonsStyling: false

                                })
                            }
                        }else{
                            swal({
                                type: 'warning',
                                text: 'No se puede agregar un elemento vacío',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            }).then( (result)=> {

                                this.alertCapture(type)
                            })
                        }



                        //console.log('rvm', result, inputt);

                    }
                }).catch(swal.noop)

            },
            handleInputConfirm () {
                /*let inputValue = this.tags.inputValue
                if (inputValue) {
                    this.tags.dynamicTags.push(inputValue)
                }
                this.tags.inputVisible = false
                this.tags.inputValue = ''*/
            },
            handleClose (index_ky, array_choose) {
                //let index_ky = this.findIndex(array_choose, id)

                array_choose.splice(index_ky, 1);
            },
            createinArray(text, array_choose){
                //console.log(text, array_choose)
                if(text.trim().length>0 && this.findName(array_choose, text)===-1){
                    array_choose.push(text)
                    return true
                }
                return false;
            },
            addArray(contract, array_choose){
                //console.log('holaa si agrego', contract, array_choose, this.findIndex(array_choose, contract._id))
                //findIndex(array_choose, contract._id)
                if(this.findName(array_choose, contract.name)===-1){
                    array_choose.push(contract.name)
                }


            },
            find_item(text, arrayList){
                //console.log('buscando item', text, arrayList);
                //this.queriedData(text, arrayList)
                var fuse = new Fuse(arrayList, this.options);
                var result = fuse.search(text);

                if(text===''){
                    result = arrayList;
                }
                //console.log('result', result);
                return result;

            },
            findIndex(arr, text) {
                let obj = arr.find(o => o === text);
                return  arr.indexOf(obj);
            },
            findName(arr, text) {
                let obj = arr.find(o => o === text);
                return  arr.indexOf(obj);
            },
            /**********************************************************************************************************/
            checkDifference(idx_arr){
                let array_actually = this.room.beds[idx_arr]
                /*if(n!==null){
                    this.contract.errors.rooms[n] = false
                }*/
                let  selected_rooms = array_actually.items;
                let full_rooms = array_actually.types;
                full_rooms.forEach(item => {
                    item.status = true;
                });

                selected_rooms.forEach(item => {
                    //console.log('idx_arr', item._id)
                    //console.log('encontrados', full_rooms.findIndex(room => room._id === item._id))
                    let idx = full_rooms.findIndex(room => room._id === item._id);
                    if(idx>=0){
                        //full_rooms[idx].status = false;
                        //this.room.beds[idx_arr].types[idx].status = false;
                        /*let temp_item = this.room.beds[idx_arr].types[idx];
                        temp_item.status = false;

                        this.room.beds[idx_arr].types.splice(idx, 1, temp_item);*/


                        this.$set(this.room.beds[idx_arr].types[idx], 'status', false);
                        //console.log('idx_arr', this.room.beds[idx_arr])
                    }
                })
            },
            findIndex2(arr, id) {
                let obj = arr.find(o => o._id === id);
                return  arr.indexOf(obj);
            },


            addBed(idx_arr){
                this.room.beds[idx_arr].items.push({
                    number: '',
                    _id:''
                });
                this.checkDifference(idx_arr);

                //console.log('Agregar cama');
            },
            deleteBed(idx_arr, idx_key){
                this.room.beds[idx_arr].items.splice(idx_key, 1);
                this.checkDifference(idx_arr);

            },
            addArrayBed(idx_arr){
                let idx = this.room.beds.length;
                let types_array = JSON.parse(JSON.stringify(this.room_info.types_bed));
                this.room.beds.push({
                    _id: '',
                    group: (idx+1),
                    types:types_array,
                    items:[
                        {
                            number: '',
                            _id:''
                        }
                    ]
                });
                //this.checkDifference();

                //console.log('Agregar cama');
            },
            rmArrayBed(idx_arr){
                this.room.beds.splice(idx_arr, 1);

            },
            onChangeImage(e, section) {
               //console.log('event', e.target.files)
                if(e.target.files.length){
                    const image = e.target.files[0];
                   //console.log(image, 'image');
                    const reader = new FileReader();
                    //this.room.image[section].img = image
                    reader.readAsDataURL(image);
                    reader.onload = e =>{
                        this.room.image[section].img = e.target.result;
                    };
                    /*switch (section) {
                        case 0:
                            this.room.image.top = image
                            break
                        case 'item_a':
                            this.room.image.item_a = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_a_front = e.target.result;
                            };
                            break;
                        case 'item_b':
                            this.room.image.item_b = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_b_front = e.target.result;
                            };
                            break;
                        case 'item_c':
                            this.room.image.item_c = image
                            reader.readAsDataURL(image);
                            reader.onload = e =>{
                                this.room.image.item_c_front = e.target.result;
                            };
                            break;

                    }*/
                }
            },


            addAttachment(file, fileList){
               //console.log('test add', file.file, fileList);

                const image = file.file;
                const reader = new FileReader();
                //this.room.image[section].img = image
                reader.readAsDataURL(image);
                reader.onload = e =>{

                    this.listImgs.push( {
                        _id: '',
                        name: image.name,
                        url: e.target.result,
                        onServer: false
                    });
                };





                /*if(e.target.files.length){
                    const image = e.target.files[0];
                    console.log(image, 'image');
                    const reader = new FileReader();
                    //this.room.image[section].img = image
                    reader.readAsDataURL(image);
                    reader.onload = e =>{
                        this.room.image[section].img = e.target.result;
                    };

                }*/



            },
            handleExceed(file, fileList) {
               //console.log('handleExceed', fileList);
                if (fileList.length === 4) {

                    swal({
                        type: 'warning',
                        text: 'Se ha excedido el número imagenes permitido por habitación',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })

                    return false;
                }
            },
            handleRemove(file, fileList) {
                let idxKey = this.listImgs.findIndex(item => item.uid===file.uid)


               //console.log('RM', idxKey, file, fileList, this.listImgs);
                if(file.onServer){
                    this.imgsBash.push(file._id);
                }
                if(idxKey>=0){
                    this.listImgs.splice(idxKey, 1);
                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            submitUpload() {
               //console.log( this.$refs.upload);
            },
            onBeforeUpload(file, fileList)
            {
                const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
                const isLt1M = file.size / 1024 / 1024 < 2;

                if (!isIMAGE) {
                    swal({
                        type: 'warning',
                        text: 'El archivo de carga solo puede estar en formato de imagen',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }
                if (!isLt1M) {
                    swal({
                        type: 'warning',
                        text: 'El tamaño de la imagen no puede exceder 2 MB',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }

                //this.objAddItem(this.listImgs, file);
            },

            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

            //********************/
        }
    }

</script>
<style lang="scss">


    .el-upload-list--picture-card .el-upload-list__item-thumbnail{
        object-fit: cover;
    }


    .container-images {
        padding-top: 35px;
        .principal {
            width: 100%;
            height: 175px;
            margin-bottom: 10px;
        }
        .second{
            width: 30%;
            margin-left: 5%;
            &.first{
                margin-left: 0;
            }
        }
    }
    .box-bed{
        margin-top: 40px;
        padding: 20px 15px 0;
        //border-top: 2px solid #ddd;
        width: 100%;
        .h-title{
            font-weight: 600;
            font-size: 14px;

        }
    }
    .container-tags{
        .box-inside{
            border: 2px solid #b9b9b9;
            position: relative;
            display: inline-block;
            width: 100%;
            .box-left{
                width: 50%;
                display: inline-block;
                border: 0;
                border-right: 2px solid #b9b9b9;
                float: left;
                input{
                    width: 100%;
                    height: 35px;
                    border: 0;
                    border-bottom: 2px solid #b9b9b9;
                    border-radius: 0;
                }
                .box-items{
                    height: 175px;
                    width: 100%;
                    overflow-y: scroll;
                    padding: 5px 0;
                    label{
                        cursor: pointer;
                        font-size: 16px;
                        line-height: 24px;
                        display: block;
                        text-transform: unset;
                        padding:0 10px;
                        margin: 0;
                        &:hover{
                            background-color: rgba(31, 118, 209, .5);
                            color: #fff;
                        }
                        &:active{
                            background-color: rgba(31, 118, 209, 1);
                            color: #fff;
                        }
                    }
                }

            }
            .box-right{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                padding: 3px 0;
            }
        }

        .box-footer2{
            padding-top: 10px;
            width: 100%;
            .box-left, .box-right{
                padding: 0 10px;
                width: 50%;
                display: inline-block;
                button{
                    width: 100% ;
                    height: 40px;
                    color: #fff;
                    background-color: #6B3E91;
                    border: 2px solid #6B3E91;
                }
                input{
                    border-radius: 0;
                    width: 100%;
                    border-width: 2px;
                    height: 40px;
                }


            }

        }

    }
    .box-button{
        //padding-left: 10px;
        .add-item{
            width: 100% ;
            height: 40px;
            color: #fff;
            background-color: #6B3E91;
            border: 2px solid #6B3E91;
            &.btn-danger{
                background-color: #F24957;
                border: 2px solid #F24957;
            }
        }
    }
    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }
    .card{
        .lbl-main{
            font-weight: 600;
            margin-top: 50px;
        }
    }
    .el-tag, .el-tag.el-tag--default {
        background-color: #eee;
    }
    .custom-error{
        label{
            font-size: 12px;
            &.text-danger{
                text-transform: none !important;


            }
        }
        .form-group{
            margin-bottom: 5px;
        }
    }
    .box-images{
        .legend-sizes{
            font-style: italic;
            text-transform: none;
            text-align: center;
            margin-top: 5px;
        }
        .remove-image{
            color: #F24957;
            font-size: 20px;
            position: absolute;
            right: 8px;
            top: 8px;
            text-shadow: 1px 2px 3px #000;
            z-index: 5;
            cursor: pointer;

        }
        .input-group{
            width: 100%;
            z-index: 3;
            .input-group-btn{
                width: 100%;
            }
            .btn{
                width: 100%;
                vertical-align: middle;
                background: transparent;
                color: #ddd;
                border: 2px solid #ddd;
                border-radius: 0;
                i{
                    line-height: 150px;
                    text-align: center;
                    margin-right: 20px;
                    font-size: 50px;
                }
            }
        }
        .img-top{
            .input-group{
                height: 180px;
            }
            .btn{
                height: 180px;
            }
            margin: 0 2.5% 0 1.5%;
            width: 96%;
            position: relative;
            img{
                width: 100%;
                height: 180px;
                object-fit: cover;
                position: absolute;
            }
        }
        .img-1_3{
            .remove-image{
                right: 3px;
                top: 13px;
            }
            .input-group{
                height: 100px;
                .btn{
                    height: 100px;
                    i{

                        line-height: 75px;
                        margin-right: 7px;
                        font-size: 30px;
                    }
                }
            }
            padding-top: 12px;
            margin: 0 1.5%;

            width: 30%;
            height: 112px;
            position: relative;
            display: inline-block;
            img{
                width: 100%;
                height: 100px;
                object-fit: cover;
                position: absolute;
            }
        }

    }
    .box-info-rateplans{
        a{
            font-weight: 600;
            color: #6B3E91;
        }
        ul{
            padding-left: 30px;
        }
        font-size: 15px;
        padding: 0 25px;
    }
</style>
